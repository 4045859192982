<template>
    <div id="dashboard">
      <b-row class="m-0" >
            <b-col sm="12" md="5" lg="4" xl="3">
              <b-row class="m-0">
                <b-col class="card-welcome">
                  <h2>
                    Tableau de bord de production
                  </h2>
                  <vs-divider color="#cccccc" class="mt-1 mb-2"></vs-divider>
                  <h2 class="text-primary">Bonjour{{ userData ? " " + userData.firstName : "" }},</h2>
                  <br>
                  <p>Retrouvez dans ce tableau de bord un rapport de la productivité avec le nombre d'évènements planifiés sur la période séledctionnée.</p>
                  <!-- <p>Vous avez <span>3 évènements</span>dans votre planning aujourd'hui</p> -->
                  <vs-divider color="#cccccc" class="m-0 mt-1 mb-50 pl-0 text-primary" position="left">
                    <span class="text-primary pl-0">Période de statistiques</span>
                  </vs-divider>
                  <date-range-picker
                    ref="picker"
                    :showDropdowns='true'
                    id="dates"
                    style="width: 100%"
                    @update="setDates"
                    :show-week-numbers="true"
                    :timePicker="false"
                    :opens="'right'"
                    :append-to-body="true"
                    :locale-data="config"
                    :ranges="defaultRange"
                    v-model="statForm.dateRange"
                    :auto-apply="true"
                  >
                  </date-range-picker>
                </b-col>
              </b-row>
              <b-row style="margin-top: 12px;">
                <b-col cols="4" class="card-action">
                  <b-link :to="'/new-affair'" class="action-content icon-add-affair">  
                    <div class="">Créer</br>une <span>affaire</span></div>
                  </b-link>
                </b-col>
                <b-col cols="4" class="card-action">
                  <b-link :to="'/directory/new-company/clients/5'" class="action-content icon-add-customer">  
                    <div>Ajouter</br>un <span>client</span></div>
                  </b-link>
                </b-col>
                <b-col cols="4" class="card-action">
                  <b-link :to="'/directory/new-contact'" class="action-content icon-add-contact">  
                    <div>Ajouter</br>un <span>contact</span></div>
                  </b-link>
                </b-col>
                <b-col cols="4" class="card-action">
                  <b-link :to="'/new-collaborator'" class="action-content icon-add-collaborator">  
                    <div>Ajouter un</br><span>collaborateur</span></div>
                  </b-link>
                </b-col>
                <b-col cols="4" class="card-action">
                  <b-link @click="showPlanning"  class="action-content icon-add-planning">  
                    <div>Consulter</br><span>mon planning</span></div>
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
  
            <b-col sm="12" md="7" lg="8" xl="9" style="padding:1rem !important">
              <!-- <b-row>
                <b-col cols="9">
                  <GmapMap
                    ref="gmap" 
                    class="card-map"
                    :center="center"
                    :zoom="7"
                  >
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in collaboratorListMapped"
                      v-if="m.position.lat!==0"
                      :position="m.position"
                      :clickable="true"
                      :draggable="true"
                      @click="center=m.position"
                    />
                  </GmapMap>
                </b-col>
                <b-col cols="3">
                  <div class="card-stats success">
                    <div class="d-flex align-items-center">
                      <div class="number">1800</div>
                      <div class="libele">heures<br>planifiées</div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                      <div class="chip">120 évènements</div>
                    </div>
                  </div>
                  <div class="card-stats primary">
                    <div class="d-flex align-items-center">
                      <div class="number">65</div>
                      <div class="libele">collaborateurs<br>actifs</div>
                    </div>
                    <vs-divider color="#cccccc" class="mt-1 mb-2"></vs-divider>
                    <div class="d-flex align-items-center">
                      <div class="number">132</div>
                      <div class="libele">collaborateurs<br>sans planification</div>
                    </div>
                  </div>
                </b-col>
              </b-row>   -->
              <b-row>
                <b-col md="12" lg="8" xl="8">
                  <div class="cart-chart px-0 mb-0 h-100" id="statsByDays" v-if="statsFromPlanning.loaded">
                    <vue-apex-charts type="bar" height="350" :options="statsFromPlanning.chartOptions" :series="statsFromPlanning.series"></vue-apex-charts>
                  </div>
                </b-col>
                <b-col  md="12" lg="4" xl="4">
                  <div class="card-stats success">
                    <div class="d-flex align-items-center">
                      <div class="number">0</div>
                      <div class="libele">heures<br>planifiées</div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                      <div class="chip">0 évènements</div>
                    </div>
                  </div>
                  <!-- <div class="card-stats primary">
                    <div class="d-flex align-items-center">
                      <div class="number">65</div>
                      <div class="libele">collaborateurs<br>actifs</div>
                    </div>
                    <vs-divider color="#cccccc" class="mt-1 mb-2"></vs-divider>
                    <div class="d-flex align-items-center">
                      <div class="number">132</div>
                      <div class="libele">collaborateurs<br>sans planification</div>
                    </div>
                  </div> -->
                  <div class="cart-chart mb-0" id="statsbyEventType" v-if="statsbyEventType.loaded">
                    <h3>Répartition des évènements</h3>
                    <vue-apex-charts type="donut" :options="statsbyEventType.chartOptions" :series="statsbyEventType.series"></vue-apex-charts>
                  </div>
                </b-col> 
              </b-row>
              
              <b-row>
                <b-col cols="12">
                  <h3>Localisation des affaires </h3>
                  <div class="cart-map" style="height: 500px; width: 100%">
                    <l-map
                      v-if="showMap"
                      :zoom="zoom"
                      :center="center"
                      :options="mapOptions"
                      style="border-radius: 5px;"
                      @update:center="centerUpdate"
                      @update:zoom="zoomUpdate"
                    >
                      <l-tile-layer
                        :url="url"
                        :attribution="attribution"
                      />
                      <!-- <l-marker :lat-lng="withPopup">
                        <l-popup>
                          <div @click="innerClick">
                            I am a popup
                            <p v-show="showParagraph">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                              sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
                              Donec finibus semper metus id malesuada.
                            </p>
                          </div>
                        </l-popup>
                      </l-marker>
                      <l-marker :lat-lng="withTooltip">
                        <l-tooltip :options="{ permanent: true, interactive: true }">
                          <div @click="innerClick">
                            I am a tooltip
                            <p v-show="showParagraph">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                              sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
                              Donec finibus semper metus id malesuada.
                            </p>
                          </div>
                        </l-tooltip>
                      </l-marker> -->
                    </l-map>
                  </div>
                </b-col>
              </b-row>
            </b-col>
  
      </b-row>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  import { BCard, BCardText, BLink } from 'bootstrap-vue'
  import { latLng } from "leaflet";
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
  import VueApexCharts from 'vue-apexcharts'
  
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  var dayjs = require('dayjs')
  var quarterOfYear = require('dayjs/plugin/quarterOfYear')
  var utc = require('dayjs/plugin/utc')
  var timezone = require('dayjs/plugin/timezone')
  var advanced = require('dayjs/plugin/advancedFormat')
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Europe/Paris')
  dayjs.extend(advanced)
  dayjs.extend(quarterOfYear)
  const config = {
    direction: 'ltr',
    format: 'dd/mm/yyyy',
    separator: ' - ',
    applyLabel: 'Appliquer',
    cancelLabel: 'Annuler',
    weekLabel: 'S',
    customRangeLabel: 'Période personnalisée',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ],
    firstDay: 1
  }
  var defaultRange = {
    'Ce mois-ci': [
      new Date(
        dayjs()
          .startOf('month')
          .hour(12)
      ),
      new Date(dayjs().endOf('month'))
    ],
    'Ce trimestre': [
      new Date(
        dayjs()
          .startOf('quarter')
          .hour(12)
      ),
      new Date(dayjs().endOf('quarter'))
    ],
    'Cette annéee': [
      new Date(
        dayjs()
          .startOf('year')
          .hour(12)
      ),
      new Date(dayjs().endOf('year'))
    ],
    'Année prochaine': [
      new Date(
        dayjs()
          .add(1, 'year')
          .startOf('year')
          .hour(12)
      ),
      new Date(
        dayjs()
          .add(1, 'year')
          .endOf('year')
      )
    ],
    'Année dernière': [
      new Date(
        dayjs()
          .subtract(1, 'year')
          .startOf('year')
          .hour(12)
      ),
      new Date(
        dayjs()
          .subtract(1, 'year')
          .endOf('year')
      )
    ]
  }
  
  export default {
    components: {
      BCard,
      BCardText,
      BLink,
      ApexCharts,
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      LTooltip,
      VueApexCharts,
      DateRangePicker
    },
    data() {
      return {
        defaultRange: defaultRange,
        config: config,
        
        statForm:{
          dateRange: {
            startDate: dayjs().subtract(7, 'day').format("YYYY-MM-DD"),
            endDate:  dayjs().add(30,'day').format("YYYY-MM-DD")
          },
        },
  
        zoom: 13,
        center: latLng(47.41322, -1.219482),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(47.41322, -1.219482),
        withTooltip: latLng(47.41422, -1.250482),
        currentZoom: 11.5,
        currentCenter: latLng(47.41322, -1.219482),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5
        },
        showMap: true,
  
        userData : JSON.parse(localStorage.getItem('userInfo')),
  
        statsFromPlanning:{
          loaded:false,
          series: [],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 300,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 5
              },
            },
            xaxis: {
              type: 'datetime',
              categories: [],
              labels: { hideOverlappingLabels: true, datetimeFormatter: { day: 'dd/MM', } }
            },
            legend: {
              position: 'bottom'
            },
            fill: {
              opacity: 1
            }
          }
        },
        statsbyEventType:{
          loaded:false,
          series:[],
          chartOptions: {
            chart: {
              type: 'pie',
            },
            labels: [],
            colors: [],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            legend: {
              position: 'bottom'
            },
            plotOptions: {
              pie: {
                expandOnClick: false
              }
            },
            dataLabels: {
              enabled: false,
              formatter: function (val) {
                return val + "J"
              },
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: "Total",
                    value: 122
                  }
                }
              }
            }
          },
        },
  
        dataStatsEventTypesByDay:[
          { 
            eventTypeId:1,
            color:"#0c3571",
            name:"Développement",
            data:[0,6,6,6,6, 0,0, 4,4,4,4,4, 0,0, 5,5,5,5,5, 0,0, 6,6,4,2,2]
          },
          { 
            eventTypeId:2,
            color:"#BDC3C8",
            name:"Formation",
            data:[0,0,0,0,0, 0,0, 2,2,2,2,2, 0,0, 0,0,0,0,0, 0,0, 0,0,0,0,0]
          },
          { 
            eventTypeId:3,
            color:"#E84B3C",
            name:"Arrêt Maladie",
            data:[0,0,0,0,0, 0,0, 0,0,0,0,0, 0,0, 0,0,0,0,0, 0,0, 0,0,0,0,0]
          },
          { 
            eventTypeId:4,
            color:"#F2C511",
            name:"Congés Payés",
            data:[0,0,0,0,0, 0,0, 0,0,0,0,0, 0,0, 1,1,1,1,1, 0,0, 0,0,0,0,0]
          }
        ],
        dataStatsEventTypesGlobal:{
          labels: ["Développement","Formation", "Arrêt Maladie", "Congés Payés"],
          colors: ["#0c3571", "#BDC3C8", "#E84B3C", "#F2C511"],
          data:[87,10,0,5]
        }
        
      };
    },
    computed: {
      ...mapGetters([
        "collaboratorsList",
        "planningViewsList",
        "statsEventTypesGlobal",
        "statsEventTypesByDay",
        "datePeriode",
      ]),
      collaboratorListMapped () {
          return this.$store.getters.collaboratorsList.map(elem => {
              return {
                id:elem.id,
                position: {lat: elem.latitude, lng:  elem.longitude},
              }
          })
      },    
      // statsEventTypesByDayMapped () {
      //     return this.$store.getters.statsEventTypesByDay.map(elem => {
      //         return {
      //           color:elem.color,
      //           eventTypeId: elem.eventTypeId,
      //           name: elem.name,
      //           data: elem.numberEvents,
      //         }
      //     })
      // }   
    },
    // watch: {
    //   datePeriode: function(val, oldVal){
    //     this.statsFromPlanning.chartOptions.xaxis.categories=val
    //     this.$nextTick(() => {
    //       this.statsFromPlanning.loaded = true
    //     });
    //   },
    // },
    mounted(){
      this.setDates(this.statForm.dateRange)
  
      // this.statsbyEventType.series=this.dataStatsEventTypesGlobal.data
      // this.statsbyEventType.chartOptions.labels=this.dataStatsEventTypesGlobal.labels
      // this.statsbyEventType.chartOptions.colors=this.dataStatsEventTypesGlobal.colors
  
      // this.statsFromPlanning.series=this.dataStatsEventTypesByDay
      // this.statsFromPlanning.chartOptions.xaxis.categories=this.dataPeriode
  
      // this.$nextTick(() => {
      //    this.statsbyEventType.loaded = true
      //    this.statsFromPlanning.loaded = true
      // });
    },
    methods: {
      ...mapActions([
        "fetchStatsEventTypesGlobal",
        "fetchStatsEventTypesByDay",
        "fetchDatePeriode"
      ]),
      async setDates (dates) {
        this.statsFromPlanning.loaded = false
        await this.$store.dispatch("fetchDatePeriode", { startDate:new Date(dates.startDate).toISOString(), endDate:new Date(dates.endDate).toISOString()}).then(res => {
          this.$nextTick(() => {
            this.statsFromPlanning.chartOptions.xaxis.categories=this.datePeriode
          })
        })
        await this.$store.dispatch("fetchStatsEventTypesByDay", { startDate:new Date(dates.startDate).toISOString(), endDate:new Date(dates.endDate).toISOString()}).then(res => {
          this.$nextTick(() => {
            this.statsFromPlanning.series = this.statsEventTypesByDay //Mapped
          });
        })
        this.statsFromPlanning.loaded = true
  
        this.statsbyEventType.loaded = false
        await this.$store.dispatch("fetchStatsEventTypesGlobal", { startDate:new Date(dates.startDate).toISOString(), endDate:new Date(dates.endDate).toISOString()}).then(res => {
          this.$nextTick(() => {
            this.statsbyEventType.series=this.statsEventTypesGlobal.numberEvents
            this.statsbyEventType.chartOptions.labels=this.statsEventTypesGlobal.name
            this.statsbyEventType.chartOptions.colors=this.statsEventTypesGlobal.color
            this.statsbyEventType.loaded = true
          });
        })
        
  
        if (dates.startDate != null) {
          this.statForm.startDate = new Date(dates.startDate).toISOString()
          this.statForm.endDate = dates.endDate
            ? new Date(dayjs(dates.endDate).format("YYYY-MM-DD"))
            : new Date(dayjs().format("YYYY-MM-DD"))
        } else {
          this.statForm.startDate = ''
          this.statForm.endDate = ''
        }
      },
  
      showPlanning(){
        this.$router.push({ name: 'planning', params: { id: this.planningViewsList[0].id, title: "Planning : "+ this.planningViewsList[0].name, tips:"Planning : "+this.planningViewsList[0].name } })
      },
  
      zoomUpdate(zoom) {
        this.currentZoom = zoom;
      },
      centerUpdate(center) {
        this.currentCenter = center;
      },
      showLongText() {
        this.showParagraph = !this.showParagraph;
      },
      innerClick() {
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  